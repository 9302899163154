
function Footer() {
    return (
      <>
        <footer>
            <a href="https://ehsanbazaei.blogspot.com/" target="_blank">Blog</a> |
            <a href="https://behance.net/artdesignehsan" target="_blank">Behance</a> |
            <a href="https://github.com/ebazaei" target="_blank">GitHub</a> |
            <a href="https://www.linkedin.com/in/ehsanbazaei" target="_blank">Linkedin</a> |
            <a href="https://www.instagram.com/artdesignehsan/" target="_blank">Instagram</a> |
            <a href="./data/pdf/Portfolio-Ehsan-Bazaei-v1.pdf" target="_blank">Portfolio</a> 
            <div>
                Copyright 2024 © <a href="https://www.bazaei.com/">Ehsan Bazaei</a> - All Rights Reserved.
            </div>          
        </footer>
      </>
    );
  }
  
  export default Footer;