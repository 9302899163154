import BTNComponent from "./BTNComponent";
import logoCharacter from '../images/characters/logo.png';
import { useState, useEffect } from "react";

import ch1 from '../images/characters/ch1.jpg';
import ch2 from '../images/characters/ch2.jpg';
import ch3 from '../images/characters/ch3.jpg';
import ch4 from '../images/characters/ch4.jpg';
import ch5 from '../images/characters/ch5.jpg';

function CharacterSlider() {

  const [count, setCount] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if(count < 4){  
                setCount((count) => count + 1);
            }else if(count >= 4){
                setCount(0);
            }
        }, 3000);
    });


    return (
      <>
              <div id="activeSlider6" >

                <div className="character-link">
                  <BTNComponent idSelectProps="character"/>
                </div>

                <div className="characterBoxText">
                  <img src={logoCharacter} alt=""/>
                  <h2>CHARACTER</h2>
                  <h3>Design</h3>
                  <h4>Ehsan bazaei</h4>
                </div>
                <div className="chWitBg"> </div>
                {/* <!-- Ball bg --> */}
                <canvas className="teststg"></canvas>
                <div className="characters">
                  <div className="webSlides"></div>
                  {CharacterSlideShow()}
                </div>
              </div>  
      </>
    );


    function CharacterSlideShow(){    
      function CHSlideShowIn1(){
        return <img src={ch1} alt="Character Design" className="sliderCH"/>;
      }
      function CHSlideShowIn2(){
        return <img src={ch2} alt="Character Design" className="sliderCH"/>;
      }
      function CHSlideShowIn3(){
        return <img src={ch3} alt="Character Design" className="sliderCH"/>;
      }
      function CHSlideShowIn4(){
        return <img src={ch4} alt="Character Design" className="sliderCH"/>;
      }
      function CHSlideShowIn5(){
        return <img src={ch5} alt="Character Design" className="sliderCH"/>;
      }

      if(count === 0){
          return <CHSlideShowIn1/>;
      }else if(count === 1){
          return <CHSlideShowIn2/>;
      }else if(count === 2){
          return <CHSlideShowIn3/>;
      }else if(count === 3){
          return <CHSlideShowIn4/>;
      }else if(count === 4){
          return <CHSlideShowIn5/>;
      }else{
          return <CHSlideShowIn3/>;
      }
  }



}
  
export default CharacterSlider;