import BTNComponent from './BTNComponent';


function AboutSlider() {


    return (
      <>
        <div id="activeSlider1" >
            <div className="btn-about-list">
                <BTNComponent idSelectProps="aboutBtn"/>
            </div>          
            <h2>Web & Graphic Designer</h2>
            <div className="resume-box-main">
                <div className="slider1-a slider-all" id="slider1A"> </div>
                <div className="slider2-l slider-all" id="slider2L"> </div>
                <div className="slider2-r slider-all" id="slider2R"> </div>
                <div className="slider3-l slider-all" id="slider3L"> </div>
                <div className="slider3-r slider-all" id="slider3R"> </div>
                <div className="slider4-l slider-all" id="slider4L"> </div>
                <div className="slider4-r slider-all" id="slider4R"> </div>
                <div className="slider5-l slider-all" id="slider5L"> </div>
                <div className="slider5-r slider-all" id="slider5R"> </div>
            </div>
        </div>
      </>
    );
}
  
export default AboutSlider;