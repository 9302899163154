import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../category.css';
import BgColorCat from '../CssCategoryBg';
import LoaderLogo from '../../mainpage/LoaderLogo';
import BtnPageComponent from '../BtnPageComponent';
import Footer from '../footerInPage';
import gallery from "./gallery.json";
import GalleryLink from './galleryLink';
import parse from 'html-react-parser';   
import { json, useParams } from 'react-router-dom';



function MultimediaEntry(){


    let { id } = useParams();
    let idNum = Number(id);
    let entryTitle = gallery[idNum].title;
    let summary = gallery[idNum].summary;
    let keywords = gallery[idNum].keywords;
    let seoContent = gallery[idNum].seocontent;
    let entryImg = gallery[idNum].largeimg;
    let content = JSON.parse(JSON.stringify(gallery[idNum].content));

    return(
    <>
        <HelmetProvider>
        <Helmet>
            <title>{entryTitle}</title>
            <meta name="description" content={seoContent} />
            <meta name="keywords" content={keywords} />
        </Helmet>
        </HelmetProvider>
        <BgColorCat color="#95c11f"/>
        <LoaderLogo/>
        <BtnPageComponent catName="multimedia"/>

        <div className='entry-cat-stage'>
            
        <header>
            <h1>{entryTitle}</h1>
            </header>

            <main className='entry-stage'>

                <article className='right-stage'>
                    <img src={entryImg} alt={entryTitle} title={entryTitle}/>
                    <h2>{summary}</h2>
                    <div className='div-free'> {parse(content)} </div>                   
                </article>

                <section className='left-stage'>
                    <div className='entry-section-contin'>
                        <GalleryLink/>
                    </div>
                </section>

            </main> 

            <aside className='entry-aside-cat-name' style={{color : "#95c11f"}}>
            Multimedia Design
            </aside>
        </div> 

        <Footer/>
        
    </>
    )


      


}

export default MultimediaEntry;