import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../category.css';
import BgColorCat from '../CssCategoryBg';
import LoaderLogo from '../../mainpage/LoaderLogo';
import BtnCatComponent from '../BtnCatComponent';
import Footer from '../footerInPage';
import GalleryLink from './galleryLink';
import data from "./data.json";
import parse from 'html-react-parser'; 


function MultimediaInPage(){

    return(
    <>
        <HelmetProvider>
        <Helmet>
            <title>Multimedia and Digital Catalogs Design</title>
            <meta name="description" content="Multimedia design: A practical and beautiful solution by combining technology and art" />
            <meta name="keywords" content="designer, ontwerper, ehsan bazaei, multimedia, digital catalog, graphic, animation" />
        </Helmet>
        </HelmetProvider>
        <BgColorCat color="#95c11f"/>
        <LoaderLogo/>
        <BtnCatComponent catName="multimedia"/> 

        <div className='cat-stage'>
            
        <header>
            <h1>Multimedia and Digital Catalogs design | Ehsan Bazaei</h1>
            </header>

            <main className='main-stage'>
                
                <article className='article-cat-name'>
                    {data &&
                        data.map((record) => {
                            return (
                                <div key={record.id}>
                                    <img key={record.id} src={record.imgmain} className='article-img' alt={record.title} title={record.title}/>
                                    <p>{parse(JSON.parse(JSON.stringify(record['content'])))}</p>
                                </div>
                            );
                        })
                    } 
                </article>

                <section className='section-cat-name'>
                    <div className='section-contin'>
                        <GalleryLink/>
                    </div>
                </section>

            </main> 

            <aside className='aside-cat-name' style={{color : "#95c11f"}}>
            Multimedia Design
            </aside>
        </div> 

        <Footer/>
    </>
    )
}

export default MultimediaInPage;