import { Link } from 'react-router-dom';
import gallery from "./gallery.json";

function GalleryLink() {

    return (
      <>
            {gallery &&
                gallery.map((record) => {
                    return (
                        <div key={record.id} className='section-entry'>
                                <Link key={record.id} to={`/paintinginentry/${record.id}`}  >
                                <img key={record.id} src={record.tmbimg} className='section-img' alt={record.title} title={record.title} />
                                </Link>
                        </div>
                    );
                })
            } 

      </>
    );


  }
  
  export default GalleryLink;