import { useNavigate } from 'react-router-dom';


function BTNComponent(props) {
    function FuncClickLoader(color, id) {
        const loaderLogo = document.getElementById("loaderLogo");
        loaderLogo.style.display = "block";
        loaderLogo.style.backgroundColor = color;
    }



    const navigate = useNavigate();

    const idSelectProps = props.idSelectProps;

    const handleClick1 = () => {
        setTimeout(() => {
            navigate('/aboutpage');
            document.body.style.backgroundColor = "#951b81";
        }, 2000);
    };  
    const handleClick2 = () => {
        setTimeout(() => {
            navigate('/webdesignpage');
            document.body.style.backgroundColor = "#b6050f";
        }, 2000);
    };  
    const handleClick3 = () => {
        setTimeout(() => {
            navigate('/illustration');
            document.body.style.backgroundColor = "#f39200";
        }, 2000);
    };  
    const handleClick4 = () => {
        setTimeout(() => {
            navigate('/graphicdesign');
            document.body.style.backgroundColor = "#f39200";
        }, 2000);
    }; 
    const handleClick5 = () => {
        setTimeout(() => {
            navigate('/multimediadesign');
            document.body.style.backgroundColor = "#95c11f";
        }, 2000);
    }; 
    const handleClick6 = () => {
        setTimeout(() => {
            navigate('/characterdesign');
            document.body.style.backgroundColor = "#009640";
        }, 2000);
    }; 
    const handleClick7 = () => {
        setTimeout(() => {
            navigate('/painting');
            document.body.style.backgroundColor = "#0069b4";
        }, 2000);
    }; 

    if(idSelectProps === "aboutBtn") {
        return (
        <>
            <button onClick={()=>{FuncClickLoader('#951b81',1);handleClick1();return false;}}>Biografie</button> | 
            <button onClick={()=>{FuncClickLoader('#951b81',1);handleClick1();return false;}}>Portfolio</button> |
            <button onClick={()=>{FuncClickLoader('#951b81',1);handleClick1();return false;}}>Contact me</button>
        </>
        );
    }else
    if(idSelectProps === "webDesignBtnList"){
        return (
        <>
            <button onClick={()=>{FuncClickLoader('#b6050f',2);handleClick2();return false;}}>Web Design Showcase</button>
        </>
        );
    }else
    if(idSelectProps === "illustration"){
        return (
        <>
            <button onClick={()=>{FuncClickLoader('#f39200',3);handleClick3();return false;}}>Cartoon Showcase</button>
        </>
        );
    }else
    if(idSelectProps === "graphicdesign1"){
        return (
        <>
            <button onClick={()=>{FuncClickLoader('#ffde00',4);handleClick4();return false;}}>Showcase</button>
        </>
        );
    }else
    if(idSelectProps === "graphicdesign2"){
        return (
        <>
            <div className="itemBoxText2" style={{animationDelay: "1.0s"}}> <span className="bulletcolor2" style={{backgroundColor: "#ee0777"}}> </span> <button onClick={()=>{FuncClickLoader('#ffde00',4);handleClick4();return false;}} className="item2Plek">Folder</button> </div>
            <div className="itemBoxText2" style={{animationDelay: "1.3s"}}> <span className="bulletcolor2" style={{backgroundColor: "#ff9900"}}> </span> <button onClick={()=>{FuncClickLoader('#ffde00',4);handleClick4();return false;}} className="item2Plek">catalog</button> </div>
            <div className="itemBoxText2" style={{animationDelay: "1.6s"}}> <span className="bulletcolor2" style={{backgroundColor: "#f9b256"}}> </span> <button onClick={()=>{FuncClickLoader('#ffde00',4);handleClick4();return false;}} className="item2Plek">Envelope</button> </div>
            <div className="itemBoxText2" style={{animationDelay: "1.9s"}}> <span className="bulletcolor2" style={{backgroundColor: "#fff15f"}}> </span> <button onClick={()=>{FuncClickLoader('#ffde00',4);handleClick4();return false;}} className="item2Plek">Book cover</button> </div>
            <div className="itemBoxText2" style={{animationDelay: "2.1s"}}> <span className="bulletcolor2" style={{backgroundColor: "#cada2f"}}> </span> <button onClick={()=>{FuncClickLoader('#ffde00',4);handleClick4();return false;}} className="item2Plek">Letterhead</button> </div>
            <div className="itemBoxText2" style={{animationDelay: "2.3s"}}> <span className="bulletcolor2" style={{backgroundColor: "#86c06a"}}> </span> <button onClick={()=>{FuncClickLoader('#ffde00',4);handleClick4();return false;}} className="item2Plek">Logo design</button> </div>
            <div className="itemBoxText2" style={{animationDelay: "2.5s"}}> <span className="bulletcolor2" style={{backgroundColor: "#3faf6c"}}> </span> <button onClick={()=>{FuncClickLoader('#ffde00',4);handleClick4();return false;}} className="item2Plek">CD & CD Cover</button> </div>
            <div className="itemBoxText2" style={{animationDelay: "2.7s"}}> <span className="bulletcolor2" style={{backgroundColor: "#058df6"}}> </span> <button onClick={()=>{FuncClickLoader('#ffde00',4);handleClick4();return false;}} className="item2Plek">Business Card</button> </div>
        </>
        );
    }else
    if(idSelectProps === "multimedia"){
        return (
        <>
            <button onClick={()=>{FuncClickLoader('#95c11f',5);handleClick5();return false;}}>Multimedia Showcase</button>
        </>
        );
    }else
    if(idSelectProps === "character"){
        return (
        <>
            <button onClick={()=>{FuncClickLoader('#009640',6);handleClick6();return false;}}>CHARACTER Showcase</button>
        </>
        );
    }else
    if(idSelectProps === "painting"){
        return (
        <>
            <button onClick={()=>{FuncClickLoader('#0069b4',7);handleClick7();return false;}}>Painting</button>|
            <button onClick={()=>{FuncClickLoader('#0069b4',7);handleClick7();return false;}}>Drawing</button>|
            <button onClick={()=>{FuncClickLoader('#0069b4',7);handleClick7();return false;}}>Digital Painting</button>|
            <button onClick={()=>{FuncClickLoader('#0069b4',7);handleClick7();return false;}}>Exhibitions</button>|
            <button onClick={()=>{FuncClickLoader('#0069b4',7);handleClick7();return false;}}>Others</button> 
        </>
        );
    }
    
}

export default BTNComponent;