import BTNComponent from "./BTNComponent";

function MultimediaSlider() {
    return (
      <>
            <div id="activeSlider5" >

              <div className="mmtitle">
                <BTNComponent idSelectProps="multimedia"/>
              </div>

              <span className="textMM">
                A practical solution by combining technology and art
              </span>
              <div className="mmBoxMain">
                <div className="Mslider1a sliderallMM" id="slider1A"> </div>
                <div className="Mslider2l sliderallMM" id="slider2L"> </div>
                <div className="Mslider2r sliderallMM" id="slider2R"> </div>
                <div className="Mslider3l sliderallMM" id="slider3L"> </div>
                <div className="Mslider3r sliderallMM" id="slider3R"> </div>
                <div className="Mslider4l sliderallMM" id="slider4L"> </div>
                <div className="Mslider4r sliderallMM" id="slider4R"> </div>
                <div className="Mslider5l sliderallMM" id="slider5L"> </div>
                <div className="Mslider5r sliderallMM" id="slider5R"> </div>
                <div className="dvdl"></div>
                <div className="dvdr"></div>
              </div>
            </div>
      </>
    );
  }
  
  export default MultimediaSlider;