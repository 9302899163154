import LoaderLogo from "./LoaderLogo";
import AboutSlider from "./AboutSlide";
import WebDesignSlide from "./WebDesignSlide";
import IllustrationSlider from "./IllustrationSlider";
import GraphicSlider from "./GraphicSlider";
import MultimediaSlider from "./MultimediaSlider";
import CharacterSlider from "./CharacterSlider";
import PaintSlider from "./PaintSlider";
import MainSlider from "./MainSlider";
import MenuBox from "./MenuBox";
import Footer from "./footer";


function MainPage() {
  
  {document.body.style.backgroundColor = "#e1e1e1";}   // Return bg color in main page

    return (
      <>
        <LoaderLogo/>
        <div className="box-main">
            <div className="logo-cadr" id="logocadr">
              <div className="slider-stage" id="sliderStageMain">
                    <div id="slider-stage-data">
                      <AboutSlider/>  
                      <WebDesignSlide/>
                      <IllustrationSlider/>
                      <GraphicSlider/>
                      <MultimediaSlider/>
                      <CharacterSlider/>
                      <PaintSlider/>
                    </div>
                </div>
                <div className="name-style-cat" id="namecat"> </div>
                <MainSlider/>
            </div>
            <MenuBox/>
            <Footer/>
        </div>

      </>
    );
  }
  
  export default MainPage;