import FuncIfRollOver from "./MenuBoxSlider";

function MenuBox() {
  return (
    <>
      <div className="menubox">
          <ul>
              <li><a href="#" onMouseOver={()=>{FuncIfRollOver('About me','#951b81',1)}}> 1 About Me </a></li>
              <li><a href="#" onMouseOver={()=>{FuncIfRollOver('Web design','#b6050f',2)}}> 2 Web design </a></li>
              <li><a href="#" onMouseOver={()=>{FuncIfRollOver('Digital Art, Illustration, Cartoon','#f39200',3)}}> 3 Illustration</a></li>
              <li><a href="#" onMouseOver={()=>{FuncIfRollOver('','#ffde00',4)}}> 4 Graphic Design</a></li>
              <li><a href="#" onMouseOver={()=>{FuncIfRollOver('Multimedia design','#95c11f',5)}}> 5 Multimedia design</a></li>
              <li><a href="#" onMouseOver={()=>{FuncIfRollOver('','#009640',6)}}> 6 Character Design</a></li>
              <li><a href="#" onMouseOver={()=>{FuncIfRollOver('Drawing and painting','#0069b4',7)}}>7 Drawing and painting</a></li>
          </ul>
      </div>
    </>
  );
}

 

  
export default MenuBox;