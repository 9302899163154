import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../category.css';
import BgColorCat from '../CssCategoryBg';
import LoaderLogo from '../../mainpage/LoaderLogo';
import BtnCatComponent from '../BtnCatComponent';
import Footer from '../footerInPage';
import GalleryLink from './galleryLink';
import data from "./data.json";
import parse from 'html-react-parser';


function PaintingInPage(){

    return(
    <>
        <HelmetProvider>
        <Helmet>            
            <title>Painting</title>
            <meta name="description" content="Ehsan Bazaei is a graduate of art university. His painting style is exclusive and personal and uses different painting techniques." />
            <meta name="keywords" content="designer, ontwerper, ehsan bazaei, Painting, art, exhibition, schilderijen, kunst " />
        </Helmet>
        </HelmetProvider>        
        <BgColorCat color="#0069b4"/>
        <LoaderLogo/>
        <BtnCatComponent catName="painting"/>
        <div className='cat-stage'>
            
        <header>
            <h1>Artistic drawing and painting | Ehsan Bazaei</h1>
            </header>

            <main className='main-stage'>
                
                <article className='article-cat-name'>
                    {data &&
                        data.map((record) => {
                            return (
                                <div key={record.id}>
                                    <img key={record.id} src={record.imgmain} className='article-img' alt={record.title} title={record.title}/>
                                    <p>{parse(JSON.parse(JSON.stringify(record['content'])))}</p>
                                </div>
                            );
                        })
                    } 
                </article>

                <section className='section-cat-name'>
                    <div className='section-contin'>
                        <GalleryLink/>
                    </div>
                </section>

            </main> 

            <aside className='aside-cat-name' style={{color : "#0069b4"}}>
            Drawing and Painting 
            </aside>
        </div> 

        <Footer/>
    </>
    )
}

export default PaintingInPage;