import BTNComponent from './BTNComponent';
import item1 from '../cssimg/slider3/item1.png';
import logo from '../cssimg/slider3/logo.png';


function IllustrationSlider() {
    return (
      <>
        <div id="activeSlider3">
            <div className="link-cartoon">

            <BTNComponent idSelectProps="illustration"/>

            </div>
            <img src={item1} alt="Illustrations and Cartoons" className="cartoonItem"/>
            <img src={logo} alt="Illustrations logo" className="cartoonlogo"/>
        </div>
      </>
    );
  }
  
  export default IllustrationSlider;