import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../category.css';
import BgColorCat from '../CssCategoryBg';
import LoaderLogo from '../../mainpage/LoaderLogo';
import BtnCatComponent from '../BtnCatComponent';
import Footer from '../footerInPage';
import GalleryLink from './galleryLink';
import data from "./data.json";
import parse from 'html-react-parser';   



function GraphicDesignInpage(){

    return(
    <>
        <HelmetProvider>
        <Helmet>
            <title>Graphic Design and Printing </title>
            <meta name="description" content="Designing all kinds of graphic projects in Netherland such as posters, banners, flyers, brochures, logos, business cards, etc." />
            <meta name="keywords" content="ٍEhsan Bazaei, Nederland, Netherland, posters, banners, flyers, brochures, logos, business cards, office letterheads, envelopes, creative design, Home style" />
        </Helmet>
        </HelmetProvider>
        <BgColorCat color="#ffde00"/>
        <LoaderLogo/>
        <BtnCatComponent catName="graphic"/>

        <div className='cat-stage'>
            
        <header>
            <h1>Graphic design and printing | Ehsan Bazaei</h1>
        </header>

            <main className='main-stage'>
                
                <article className='article-cat-name'>
                    {data &&
                        data.map((record) => {
                            return (
                                <div key={record.id}>
                                    <img key={record.id} src={record.imgmain} className='article-img' alt={record.title} title={record.title}/>
                                    <p>{parse(JSON.parse(JSON.stringify(record['content'])))}</p>
                                </div>
                            );
                        })
                    } 
                </article>

                <section className='section-cat-name'>
                    <div className='section-contin'>
                        <GalleryLink/>
                    </div>
                </section>

            </main> 

            <aside className='aside-cat-name' style={{color : "#ffde00"}}>
            Graphic Design
            </aside>
        </div> 

        <Footer/>
    </>
    )


}

export default GraphicDesignInpage;