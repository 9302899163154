import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../category.css';
import BgColorCat from '../CssCategoryBg';
import LoaderLogo from '../../mainpage/LoaderLogo';
import BtnCatComponent from '../BtnCatComponent';
import Footer from '../footerInPage';
import data from "./data.json";
import parse from 'html-react-parser';   


function AboutPage(){


    
    return(
    <>
    <HelmetProvider>
        <Helmet>
                <title>About Ehsan Bazaei: Designer</title>
                <meta name="description" content="Ehsan Bazaei is a frontend developer, graphic designer & artist. He has many years of experience in these fields as a designer and team manager." />
                <meta name="keywords" content="art, webdesign, ehsan bazaei, designer, frontender, graphist, artist, cartoonist, UI, UX" />
        </Helmet>
    </HelmetProvider>

        <BgColorCat color="#942681"/>
        <LoaderLogo/>
        <BtnCatComponent catName="about"/>

        <div className='cat-stage'>
            
        <header>
            <h1>Ehsan Bazaei: Web & Graphic Designer, Artist</h1>
            </header>

            <main className='main-stage'>
                
                <article className='article-cat-name-about'>
                    <img src="../images/data/about/ehsan-bazaei.jpg" className='article-img-about' alt='Ehsan bazaei' title='Ehsan Bazaei'/>
                </article>
                <article className='article-cat-name-about-vartical'>
                    <img src="../images/data/about/about-ehsan-bazaei.jpg" className='article-img-about' alt='About Ehsan bazaei' title='Ehsan Bazaei'/>
                </article>

                <section className='section-cat-name'>
                    <div className='section-contin-right'>
                        <div className='section-contin-accordion'>

                            {data &&
                                data.map((record) => {
                                    return (
                                        <div key={record.id} >
                                            <input id={record.id} type="radio" name="articles" checked/>
                                            <label for={record.id}>
                                            <h2>{record.title}</h2>
                                            </label>
                                            <div className='data-accordion'>
                                            {parse(JSON.parse(JSON.stringify(record['content-right'])))}
                                            </div>
                                        </div>
                                    );
                                })
                            } 
                        </div>
                    </div>
                </section>

            </main> 

            <aside className='aside-cat-name' style={{color : "#942681"}}>
            About me
            </aside>
        </div> 

        <Footer/>

    </>
    )
    
}


export default AboutPage;