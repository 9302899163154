function MainSlider() {
    return (
      <>
        <div id="activeSlider0" >
            <h1 className="name-style" id="nameId" ><span className="gradient">Ehsan Bazaei</span></h1>
            <div className="portrait" id="portrait"></div>
        </div>
      </>
    );
}
  
export default MainSlider;