const FuncIfRollOver = function(pageName,color,id){    
    disableAllSliderIfRollOver(); //Disable all slider function
    const findSlideId = "activeSlider" + id;
    const sliderStageDataId = "slider-stage-data-" + id;
    document.getElementById("slider-stage-data").setAttribute("class", ""); 
    document.getElementById("slider-stage-data").classList.add(sliderStageDataId);
    document.getElementById("sliderStageMain").style = "display: block";
    document.getElementById('logocadr').style = "background-image: none; animation: fade 1.5s;";
    document.getElementById('logocadr').style.backgroundColor = color;
    document.getElementById("namecat").innerText = pageName;
    document.getElementById("namecat").style.display = "block";
    document.getElementById("portrait").style.display = "none";
    document.getElementById("nameId").style.display = "none";
    document.getElementById(findSlideId).style = "display: block";



    //Multi Ball
    let canvas = document.querySelector("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    let c= canvas.getContext("2d");
    class Ball{
        constructor(){
            this.r = randomInFromInterval(10 ,100);  //Random Size
            this.x = randomInFromInterval(0+this.r ,window.innerWidth-this.r); 
            this.y = randomInFromInterval(0+this.r ,window.innerHeight-this.r); 
            this.vx = (Math.random() - 0.5)*7; 
            this.vy = (Math.random() - 0.5)*7;
            this.opacity = randomOpacity(1 ,5); //Random opacity
            this.lineSize  = randomLineSize(1,5); //Random stroke line size
            this.draw()  
        }
        draw(){
            c.beginPath(); 
            c.arc(this.x,this.y,this.r,0, 2*Math.PI)  
            c.fillStyle = "transparent";
            c.fill();
            c.stroke();
            c.strokeStyle = "#c9fd2a";  //border color
            c.globalAlpha = this.opacity; //border opacity
            c.lineWidth = this.lineSize; //border stroke size
        }
        update(){
            if(this.x+this.r > window.innerWidth || this.x-this.r < 0){   
                this.vx = -this.vx;
            }
            if(this.y+this.r > window.innerHeight || this.y-this.r < 0){   
                this.vy = -this.vy;
            }
            this.x += this.vx; 
            this.y += this.vy;
            this.draw();
        }
    }

    //Multi ball
    let balls = []
    for(let i = 0; i < 7 ; i++){ //antal
        balls.push(new Ball)
    }
    function animate(){
        c.clearRect(0,0,window.innerWidth, window.innerHeight) //delet old frame
        balls.forEach(ball=>{
            ball.update()
        })
        requestAnimationFrame(animate);
    }
    animate();
    function randomInFromInterval(min,max){
        return Math.floor(Math.random() * (max - min + 1) + min)
    }
    //select random Opacity
    function randomOpacity(min,max){
        let numR = Math.floor(Math.random() * (max - min + 1) + min);
        switch(numR){
            case 1:
                return "0.2";
            case 2:
                return "0.4";
            case 3:
                return "0.6";
            case 4:
                return "0.8";
            case 5:
                return "1.0";
        }
    }
    //select random stoke size 
    function randomLineSize(min,max){
        let numR = Math.floor(Math.random() * (max - min + 1) + min);
        switch(numR){
            case 1:
                return "1";
            case 2:
                return "2";
            case 3:
                return "3";
            case 4:
                return "5";
            case 5:
                return "7";
        }
    }
    //End Multi Ball
}

//Disable all slider function
const disableAllSliderIfRollOver = function(){  
  const allSliderDeActive = [0,1,2,3,4,5,6,7];
  for (let i = 0; i < allSliderDeActive.length; i++) {
      const findSlideId = "activeSlider" + allSliderDeActive[i];
      document.getElementById(findSlideId).style = "display: none";
  }
}



export default FuncIfRollOver;