import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../category.css';
import BgColorCat from '../CssCategoryBg';
import LoaderLogo from '../../mainpage/LoaderLogo';
import BtnCatComponent from '../BtnCatComponent';
import Footer from '../footerInPage';
import GalleryLink from './galleryLink';
import data from "./data.json";
import parse from 'html-react-parser';




function IllustrationPage(){

    return(
    <>
        <HelmetProvider>
        <Helmet>
            <title>Illustrations, caricatures and cartoons </title>
            <meta name="description" content="Cooperation with printed publications such as newspapers, magazines, etc. as an illustrator and cartoonist." />
            <meta name="keywords" content="designer, ontwerper, ehsan bazaei, cartoon, illustrator, illustration" />
        </Helmet>
        </HelmetProvider>       
        <BgColorCat color="#f39200"/>
        <LoaderLogo/>
        <BtnCatComponent catName="illustration"/>

        <div className='cat-stage'>
            
        <header>
            <h1>Illustrations, caricatures and cartoons | Ehsan Bazaei</h1>
            </header>

            <main className='main-stage'>
                
                <article className='article-cat-name'>
                    {data &&
                        data.map((record) => {
                            return (
                                <div key={record.id}>
                                    <img key={record.id} src={record.imgmain} className='article-img' alt={record.title} title={record.title}/>
                                    <p>{parse(JSON.parse(JSON.stringify(record['content'])))}</p>
                                </div>
                            );
                        })
                    } 
                </article>

                <section className='section-cat-name'>
                    <div className='section-contin'>
                        <GalleryLink/>
                    </div>
                </section>

            </main> 

            <aside className='aside-cat-name' style={{color : "#f39200"}}>
            Illustration
            </aside>
        </div> 

        <Footer/>
    </>
    )
}

export default IllustrationPage;