import Exhibition from '../images/exhibition.jpg';
import BTNComponent from './BTNComponent';


function PaintSlider() {
    return (
      <>
        <div id="activeSlider7" >
            <div className="paintBox">
                <img src={Exhibition} alt=""/>
            </div>
            <div className="paint-list" style={{textAlign: "center"}}>

                <BTNComponent idSelectProps="painting"/>

            </div>
        </div>
      </>
    );
  }
  
  export default PaintSlider;