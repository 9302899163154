import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from "./mainpage/Main";
import AboutPage from './categories/aboutpage/aboutpage';
import WebDesignPage from './categories/webdesignpage/webdesignpage';
import IllustrationPage from './categories/illustration/illustrationpage';
import GraphicDesignInpage from './categories/graphicdesign/graphicdesign';
import MultimediaInPage from './categories/multimedia/multimediainpage';
import CharacterInPage from './categories/character/characterinpage';
import PaintingInPage from './categories/painting/paintinginpage';
import GraphicEntry from './categories/graphicdesign/graphicEntry';
import CharacterEntry from './categories/character/characterEntry';
import IllustrationEntry from './categories/illustration/illustrationEntry';
import MultimediaEntry from './categories/multimedia/multimediaEntry';
import PaintinginEntry from './categories/painting/paintinginEntry';
import WebdesignEntry from './categories/webdesignpage/webEntry';


function App() {
  return (
    <>

    <div className="App">
    <HelmetProvider>
      <Helmet>
          <title>Ehsan Bazaei</title>
          <meta name="description" content="Introducing Ehsan Bazaei and showing his works in the field of website design, graphic design, painting, character design and cartoons." />
          <meta name="theme-color" content="#008f68" />
          <meta name="keywords" content="art, web design, ehsan bazaei, designer, frontender, graphist, artist, cartoonist, UI, UX, graphic design, character design, website design" />
        </Helmet>
    </HelmetProvider>
    </div>

      <Router>
        <Routes>


          <Route path='/' element={<MainPage/>}/>
          <Route path="/aboutpage" element={<AboutPage/>}/>
          <Route path="/webdesignpage" element={<WebDesignPage/>}/>
          <Route path="/illustration" element={<IllustrationPage/>}/>
          <Route path="/graphicdesign" element={<GraphicDesignInpage/>}/>
          <Route path="/multimediadesign" element={<MultimediaInPage/>}/> 
          <Route path="/characterdesign" element={<CharacterInPage/>}/> 
          <Route path="/painting" element={<PaintingInPage/>}/> 
          <Route path="/graphicentry/:id" element={<GraphicEntry/>}/> 
          <Route path="/characterentry/:id" element={<CharacterEntry/>}/> 
          <Route path="/Illustrationentry/:id" element={<IllustrationEntry/>}/>
          <Route path="/multimediaentry/:id" element={<MultimediaEntry/>}/>
          <Route path="/paintinginentry/:id" element={<PaintinginEntry/>}/>
          <Route path="/webdesignentry/:id" element={<WebdesignEntry/>}/>

        </Routes>
      </Router>
    </>
  );
}

export default App;
