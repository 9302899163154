import BTNComponent from './BTNComponent';


function GraphicSlider() {
    return (
      <>
            <div id="activeSlider4" >

                <div className="s4logotext">
                    <BTNComponent idSelectProps="graphicdesign1"/>
                    <h3>GRAPHIC</h3>
                    <h4>DESIGN</h4>
                </div>


                <div className="itemBox2">

                    <BTNComponent idSelectProps="graphicdesign2"/>

                </div>

                <span className="SquareR"></span>
                <span className="SquareL"></span>

                <div className="Lightbg">
                    <div className="handLight"></div>
                </div>

            </div>
      </>
    );
  }
  
  export default GraphicSlider;