import BTNComponent from './BTNComponent';
import { useState, useEffect } from "react";

//New counter for slideShow
function WebDesignSlide() {

    const [count, setCount] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if(count < 2){  
                setCount((count) => count + 1);
            }else if(count >= 2){
                setCount(0);
            }
        }, 3000);
    });


    return (
      <>
        <div id="activeSlider2" >    
        {/* <div className="webSlides" id="slideId-1"> <div className="testm " ></div> </div> */}

            <div className="monitor-graphic">
                {WebSlideShow()}
            </div>

            <div className="itemBox">     
                <div className="itemBoxText" style={{animationDelay: "1s"}}> <span className="bulletcolor" style={{backgroundColor: "#ffff00"}}> </span> SEO Friendly</div>
                <div className="itemBoxText" style={{animationDelay: "1.3s"}}> <span className="bulletcolor" style={{backgroundColor: "#ff9900"}}> </span> User-Friendly UI / UX</div>
                <div className="itemBoxText" style={{animationDelay: "1.6s"}}> <span className="bulletcolor" style={{backgroundColor: "#fd0715"}}> </span> Responsive Web Design</div>
                <div className="itemBoxText" style={{animationDelay: "1.9s"}}> <span className="bulletcolor" style={{backgroundColor: "#f310ce"}}> </span> Custom Website Design</div>
                <div className="itemBoxText" style={{animationDelay: "2.1s"}}> <span className="bulletcolor" style={{backgroundColor: "#058df6"}}> </span> Validate HTML & Css (W3C)</div>
            </div>
            <span className="bigcircle"></span>
            <span className="smallcircle"></span>
            <div className="link-webdesign">

                <BTNComponent idSelectProps="webDesignBtnList"/>

            </div>
        </div>

      </>
    );

    function WebSlideShow(){    
        function WebSlideShowIn1(){
            return <div className="webSlides" id="slideId-1"> <div className="monitor-graphic-photo1 fadeinstyle" ></div> </div>
        }
        function WebSlideShowIn2(){
            return <div className="webSlides" id="slideId-2"> <div className="monitor-graphic-photo2 fadeinstyle" ></div> </div>
        }
        function WebSlideShowIn3(){
            return <div className="webSlides" id="slideId-3"> <div className="monitor-graphic-photo3 fadeinstyle" ></div> </div>
        }

        if(count === 0){
            return <WebSlideShowIn1/>;
        }else if(count === 1){
            return <WebSlideShowIn2/>;
        }else if(count === 2){
            return <WebSlideShowIn3/>;
        }else{
            return <WebSlideShowIn1/>;
        }
    }
}

  
export default WebDesignSlide;