import { useNavigate } from 'react-router-dom';


function BtnCatComponent(props) {

    const catBtnForInactive = props.catName;

    function FuncClickLoader(color, id) { 
        const loaderLogo = document.getElementById("loaderLogo");
        loaderLogo.style.display = "block";
        loaderLogo.style.backgroundColor = color;
    }

    const navigate = useNavigate();

    const handleClick1 = () => {
        if(catBtnForInactive !== "about"){
            FuncClickLoader('#951b81',1);
            setTimeout(() => {
                navigate('/aboutpage');
                document.body.style.backgroundColor = "#951b81";
            }, 2000);
        }
    };  
    const handleClick2 = () => {
        if(catBtnForInactive !== "web"){
            FuncClickLoader('#b6050f',2);
            setTimeout(() => {
                navigate('/webdesignpage');
                document.body.style.backgroundColor = "#b6050f";
            }, 2000);
        }
    };  
    const handleClick3 = () => {
        if(catBtnForInactive !== "illustration"){
            FuncClickLoader('#f39200',3);
            setTimeout(() => {
                navigate('/illustration');
                document.body.style.backgroundColor = "#f39200";
            }, 2000);
        }
    };  
    const handleClick4 = () => {
        if(catBtnForInactive !== "graphic"){
            FuncClickLoader('#ffde00',4);
            setTimeout(() => {
                navigate('/graphicdesign');
                document.body.style.backgroundColor = "#db0512";
            }, 2000);
        }
    }; 
    const handleClick5 = () => {
        if(catBtnForInactive !== "multimedia"){
            FuncClickLoader('#95c11f',5);
            setTimeout(() => {
                navigate('/multimediadesign');
                document.body.style.backgroundColor = "#95c11f";
            }, 2000);
        }
    }; 
    const handleClick6 = () => {
        if(catBtnForInactive !== "character"){
            FuncClickLoader('#009640',6);
            setTimeout(() => {
                navigate('/characterdesign');
                document.body.style.backgroundColor = "#009640";
            }, 2000);
        }
    }; 
    const handleClick7 = () => {
        if(catBtnForInactive !== "painting"){
            FuncClickLoader('#0069b4',7);
            setTimeout(() => {
                navigate('/painting');
                document.body.style.backgroundColor = "#0069b4";
            }, 2000);
        }
    }; 
    const returnHome = () => {
            FuncClickLoader('#e1e1e1',8);
            setTimeout(() => {
                navigate('/');
                document.body.style.backgroundColor = "#e1e1e1";
            }, 2000);
    }; 





    return (
    <>
    <button className='btn-back' onClick={()=>{returnHome();return false;}}>Back to Home</button>

    <nav className='color-box-left'>
        <div><button className='btnbg-1' onClick={()=>{handleClick1();return false;}}> About Me</button></div>
        <div><button className='btnbg-2' onClick={()=>{handleClick2();return false;}}> Web design</button></div>
        <div><button className='btnbg-3' onClick={()=>{handleClick3();return false;}}> Illustration</button></div>
        <div><button className='btnbg-4' onClick={()=>{handleClick4();return false;}}> Graphic Design</button></div>
        <div><button className='btnbg-5' onClick={()=>{handleClick5();return false;}}> Multimedia design</button></div>
        <div><button className='btnbg-6' onClick={()=>{handleClick6();return false;}}> Character Design</button></div>
        <div><button className='btnbg-7' onClick={()=>{handleClick7();return false;}}> Drawing and painting</button></div>
    </nav>
    </>
    );
    
}

export default BtnCatComponent;