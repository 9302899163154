function LoaderLogo() {
    return (
      <>
            <div className="loader-logo" id="loaderLogo">
            <div className="stage-click"> 
                <img src="" alt="" id="stageClickImg"/>
            </div>
            </div>
        </>
    );
  }
  
  export default LoaderLogo;